import { Row, Typography } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import { contactUs } from "../../utils/utils";
import { useEffectOnce } from "../../hooks/use-effect-once";
import { scrollToTop } from "../../utils/scroll-to-top";
export const PremiumCustomerCareNo = "9429690909";
export const customerCareNo = "9035504487";

const ContactUs = () => {
  useEffectOnce(() => {
    scrollToTop();
  });
  return (
    <Row
      style={{
        flexDirection: "column",
        height: `calc(100vh - 64px)`,
      }}
      wrap={false}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          flexGrow: 1,
        }}
      >
        <Row style={{ flexDirection: "column", padding: "1rem" }}>
          <Typography.Title
            level={3}
            type="secondary"
            style={{ textAlign: "center", margin: "20px 0" }}
          >
            Contact Superfone Customer Care now
          </Typography.Title>
          <Typography.Title
            level={2}
            style={{
              textAlign: "center",
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <WhatsAppOutlined />
            <span>
              <strong>+91</strong>
              <span>{`${customerCareNo}`}</span>
            </span>
          </Typography.Title>
          <Typography.Title
            level={3}
            type="secondary"
            style={{ textAlign: "center", margin: 0 }}
          >
            Whatsapp Only
          </Typography.Title>
          <Typography.Title
            onClick={() => {
              contactUs("I need help");
            }}
            style={{
              textDecoration: "underline",
              fontSize: "1rem",
              textAlign: "center",
              cursor: "pointer",
              color: "blue",
            }}
          >
            Chat with live agent
          </Typography.Title>
        </Row>
      </Row>
    </Row>
  );
};

export default ContactUs;
