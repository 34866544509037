export const countryCodeObject = {
  IN: "+91",
};

export type CountryShortName = keyof typeof countryCodeObject;

export const removeCountryCode = (params: {
  phoneNumber?: string | null;
  country?: CountryShortName;
  numberMasking?: boolean;
  first_name?: string | null;
  last_name?: string | null;
}) => {
  const { country, phoneNumber, numberMasking, first_name, last_name } =
    params || {};
  let phoneNumberString =
    typeof phoneNumber === "string"
      ? phoneNumber.replaceAll(countryCodeObject[country || "IN"], "")
      : "";
  if (numberMasking) {
    phoneNumberString =
      phoneNumberString.slice(0, 2) + "XXXXXXXX" + phoneNumberString.slice(-2);
  }
  let name = `${first_name} ${last_name}`.trim();
  const customerPhoneWithoutPlus = name.replace("+", "");
  if (
    numberMasking &&
    customerPhoneWithoutPlus &&
    !isNaN(Number(customerPhoneWithoutPlus))
  ) {
    name = name.slice(0, 2) + "XXXXXXXX" + name.slice(-2);
  }
  return { phone: phoneNumberString, name };
};

export const getContactWithCountryCode = (params: {
  number?: string;
  country?: CountryShortName;
  numberMasking?: boolean;
  first_name?: string | null;
  last_name?: string | null;
}) => {
  const {
    country,
    number,
    numberMasking,
    first_name = "",
    last_name = "",
  } = params || {};

  let phoneNumber =
    number?.replace(countryCodeObject[country || "IN"], "") || "";
  if (numberMasking && phoneNumber) {
    phoneNumber = phoneNumber.slice(0, 2) + "XXXXXXXX" + phoneNumber.slice(-2);
  }
  let name = `${first_name} ${last_name}`.trim();
  const customerPhoneWithoutPlus = name.replace("+", "");
  if (
    numberMasking &&
    customerPhoneWithoutPlus &&
    !isNaN(Number(customerPhoneWithoutPlus))
  ) {
    name = name.slice(0, 2) + "XXXXXXXX" + name.slice(-2);
  }
  const phone = [countryCodeObject[country || "IN"] || "", phoneNumber || ""];
  return {
    phone,
    name: name || phone?.join(" ") || "",
  };
};
